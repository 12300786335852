import { defineStore } from 'pinia';
import type { Brand, Group } from '~/types/shop';

export const useShopStore = defineStore({
  id: 'useShopStore',
  state: () => ({
    groups: <Group[]>[],
    brands: <Brand[]>[]
  }),
  actions: {
    setGroups(newGroups: Group[]) {
        this.groups = newGroups
    },
    setBrands(newBrands: Brand[]) {
      this.brands = newBrands
    }
  }
});